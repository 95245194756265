<template>
  <div class="head" :class="{'noindex': index != true}">
    <!-- 首页透明头部 -->
    <div class="lucency-head">
      <div class="lucency-logo">
        <img src="../../assets/PC/head/logo.png" alt v-if="chooseRooter == 'Home' && index == true" />
        <img src="../../assets/PC/head/logo_1.png" alt v-if="index == false" />
      </div>
      <div class="lucency-navigation">
        <router-link to="/" :class="{'theChoose': chooseRooter == 'Home' && index == false}">首页</router-link>
        <router-link to="/serve" :class="{'theChoose': chooseRooter == 'serve'}">产品与服务</router-link>
        <router-link to="/cooperation" :class="{'theChoose': chooseRooter == 'cooperation'}">物业合作</router-link>
        <router-link to="/example" :class="{'theChoose': chooseRooter == 'example'}">案例</router-link>
        <router-link to="/about" :class="{'theChoose': chooseRooter == 'about'}">关于我们</router-link>
      </div>
      <a href="https://admin.leyej.com" class="lucency-system">进入管理系统</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "head",
  props: {
    index: Boolean,
    chooseRooter: String
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (this.chooseRooter == "Home") {
        var that = this;
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop > 800) {
          that.index = false;
        } else {
          that.index = true;
        }
      }
    }
  }
};
</script>

<style>
.head {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
}
.lucency-head {
  padding: 4px 18%;
  color: #ffffff;
  font-size: 16px;
  display: flex;
}
.lucency-logo {
  float: left;
}
img {
  width: 66px;
  height: 66px;
}

.lucency-navigation {
  margin: 0 auto;
  display: flex;
  margin-top: 21px;
}
.lucency-navigation a {
  margin-left: 40px;
  cursor: pointer;
}
.lucency-system {
  float: right;
  margin-top: 21px;
  cursor: pointer;
}

.noindex .lucency-head,
.noindex .lucency-navigation {
  background-color: #ffffff;
  color: #222222;
}

.theChoose {
  color: #07bac6 !important;
  position: relative;
  border-bottom: 2px solid #07BAC6;
}

a {
  text-decoration: none;
  color: inherit;
}
/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
  color: inherit;
}
/*已经访问过的链接*/
a:visited {
  text-decoration: none;
  color: inherit;
}
/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
  color: inherit;
}
/* 正在点击的链接*/
a:active {
  text-decoration: none;
  color: inherit;
}
a:-webkit-any-link {
  color: inherit;
}
</style>
  
