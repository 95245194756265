<template>
  <div class="serve">
    <!-- S head -->
    <el-head :index="false" chooseRooter="serve"></el-head>
    <!-- E head -->

    <!-- S banner -->
    <div class="serve-banner">
      
      <img src="../../assets/PC/serve/banner_7.png" alt />
    </div>
    <!-- E banner -->

    <!-- S icon -->
    <div class="serve-icon">
      <el-cell message="精英运营"></el-cell>
      <div class="serve-icon-body">
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_1.png" alt />
          <div>整体改造、提升形象价值</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_2.png" alt />
          <div>品牌赋值</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_3.png" alt />
          <div>多渠道推广</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_4.png" alt />
          <div>专业管理团队</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_5.png" alt />
          <div>完整供应链，一站式服务</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_6.png" alt />
          <div>财务核算</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_7.png" alt />
          <div>人才培训</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_8.png" alt />
          <div>稳定的资金收益</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_9.png" alt />
          <div>提升租金溢价</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_10.png" alt />
          <div>系统化管理</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_11.png" alt />
          <div>提供资金扶持</div>
        </div>
        <div class="serve-icon-body-content">
          <img src="../../assets/PC/serve/product_icon_12.png" alt />
          <div>资产盘活</div>
        </div>
      </div>
    </div>
    <!-- E icon -->

    <!-- S swiper -->
    <div class="serve-swiper">
      <el-cell message="管理系统"></el-cell>

      <div class="serve-swiper-body">
        <div class="serve-swiper-body-option">
          <div class="serve-swiper-body-option-content" :class="{'options': myindex == 0}" @click="onChangeSwiper(0)">
            酒店、公
            寓双模式
          </div>
          <div class="serve-swiper-body-option-content" :class="{'options': myindex == 1}" @click="onChangeSwiper(1)">
            线上收租
            直达账单
          </div>
          <div class="serve-swiper-body-option-content" :class="{'options': myindex == 2}" @click="onChangeSwiper(2)">
            智能设备
            兼容到位
          </div>
          <div class="serve-swiper-body-option-content" :class="{'options': myindex == 3}" @click="onChangeSwiper(3)">
            高度自定义
            随心设置
          </div>
          <div class="serve-swiper-body-option-content" :class="{'options': myindex == 4}" @click="onChangeSwiper(4)">
            界面简洁
            操作简单
          </div>
          <div class="serve-swiper-body-option-content" :class="{'options': myindex == 5}" @click="onChangeSwiper(5)">
            财务报表
            清晰明了
          </div>
        </div>

        <div class="serve-swiper-box">
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
          >
            <swiper-slide>
              <img src="../../assets/PC/serve/管理系统.png" alt />
            </swiper-slide>
            <swiper-slide>
              <img src="../../assets/PC/serve/线上交租.png" alt />
            </swiper-slide>
            <swiper-slide>
              <img src="../../assets/PC/serve/智能设备.png" alt />
            </swiper-slide>
            <swiper-slide>
              <img src="../../assets/PC/serve/高度自定义.png" alt />
            </swiper-slide>
            <swiper-slide>
              <img src="../../assets/PC/serve/界面简洁.png" alt />
            </swiper-slide>
            <swiper-slide>
              <img src="../../assets/PC/serve/财务报表.png" alt />
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </div>
    <!-- E swiper -->

    <!-- S equipment -->
    <div class="serve-equipment">
      <el-cell message="智能设备"></el-cell>

      <div class="serve-equipment-body">
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/ZB电表.jpg" alt="图片" />
          <div>电表</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/阀门.jpg" alt="图片" />
          <div>阀门</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/门锁.jpg" alt="图片" />
          <div>门锁</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/人体感应.jpg" alt="图片" />
          <div>人体感应</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/烟感.jpg" alt="图片" />
          <div>烟感</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/11.jpg" alt="图片" />
          <div>一氧化碳传感器</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/ZB水表.jpg" alt="图片" />
          <div>ZB水表</div>
        </div>
        <div class="serve-equipment-body-content">
          <img src="../../assets/PC/serve/ZB网关.jpg" alt="图片" />
          <div>ZB网关</div>
        </div>
      </div>

      <el-cell message="统兼容智能设备品牌"></el-cell>
      <div class="serve-equipment-label">
        <div class="serve-equipment-label-content">科技侠</div>
        <div class="serve-equipment-label-content">火河</div>
        <div class="serve-equipment-label-content">云丁</div>
        <div class="serve-equipment-label-content">涂鸦</div>
        <div class="serve-equipment-label-content">TCL</div>
        <div class="serve-equipment-label-content">千宝</div>
      </div>
    </div>
    <!-- E equipment -->
    <el-footer></el-footer>
  </div>
</template>

<script>
import elHead from "../../components/PC/head";
import elCell from "../../components/PC/cell";
import elFooter from "../../components/PC/footer";
export default {
  name: "serve",
  components: {
    elHead,
    elCell,
    elFooter
  },
  methods: {
    onSlideChange() {
      this.myindex = this.$refs.mySwiper.swiper.realIndex;
    },
    onChangeSwiper(num) {
      this.myindex = num;
      this.$refs.mySwiper.swiper.slideTo(this.myindex + 1)
    }
  },
  data() {
    return {
      myindex: 0,
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true,
        realIndex: this.myindex,
        
      }
    };
  }
};
</script>

<style>
.serve {
  width: 100%;
  height: 100%;
}

.serve-banner {
  height: 500px;
}

.serve-banner img {
  width: 100%;
  height: 100%;
}

.serve-icon-body {
  display: flex;
  flex-wrap: wrap;
  width: 1200px;
  margin: 72px auto 0 auto;
}

.serve-icon-body-content {
  width: 200px;
  height: 200px;
  text-align: center;
}

.serve-icon-body-content img {
  width: 66px;
  height: 66px;
}

.serve-icon-body-content div {
  margin-top: 24px;
  font-size: 14px;
  color: #222222;
  font-weight: 400;
}

.serve-swiper {
  height: 800px;
  background: #f2f3f5;
}

.serve-swiper-body {
  width: 1040px;
  margin: 60px auto 0 auto;
  display: flex;
}

.serve-swiper-body-option {
  padding: 5px 0;
  background: #ffffff;
}

.serve-swiper-body-option-content {
  padding: 18px 104px 18px 32px;
  font-size: 16px;
  color: #565656;
  font-weight: 400;
  max-width: 65px;
}

.serve-swiper-body-option .options {
  padding: 18px 104px 18px 28px;
  font-weight: 600;
  background: rgba(7, 186, 198, 0.1);
  border-left: 4px solid #07bac6;
}

.serve-equipment-body {
  display: flex;
  margin: 72px auto 0 auto;
  width: 1200px;
  flex-wrap: wrap;
  height: 730px;
  justify-content: space-between;
}

.serve-equipment-body-content {
  width: 276px;
  height: 330px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.serve-equipment-body-content img {
  width: 220px;
  height: 220px;
  margin: 28px;
}

.serve-equipment-body-content div {
  text-align: center;
}

.serve-equipment-label {
  margin: 39px auto 80px auto;
  width: 1200px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 150px;
}

.serve-equipment-label-content {
  width: 180px;
  height: 60px;
  background: #f2f3f5;
  line-height: 60px;
  text-align: center;
}

.serve-swiper-box {
  width: 800px;
  overflow: hidden;
  margin-left: 30px;
  /* position: relative; */
}

.serve-swiper-box img {
  width: 800px;
  height: 510px;
}

.swiper-pagination {
  left: 1050px;
}

.serve-swiper .serve-swiper-box .swiper-pagination-bullet-active {
  width: 16px;
  height: 8px;
  background: #07bac6;
  border-radius: 4px;
  opacity: 1;
}

.serve-swiper-box .swiper-pagination-bullet {
  margin-right: 8px;
  background: #07bac6;
  opacity: 0.25;
}
</style>
  
