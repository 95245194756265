<template>
  <div class="home">
    <!-- S head -->
    <el-head :index="true" chooseRooter="Home"></el-head>
    <div class="home-body">
      <swiper :options="swiperOption">
        <swiper-slide>
          <img src="../../assets/PC/index/index.jpg" alt />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/PC/index/index_2.jpg" alt />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/PC/index/index_3.jpg" alt />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/PC/index/index_4.jpg" alt />
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/PC/index/index_5.jpg" alt />
        </swiper-slide>
      </swiper>
    </div>
    <!-- E head -->

    <!-- S operate -->
    <div class="home-operate">
      <el-cell message="运营我们是专业的"></el-cell>
      <ul class="operate-body">
        <li>
          <div class="operate-img">
            <img src="../../assets/PC/index/home_icon_1.png" alt />
          </div>
          <div class="operate-content">专业设计&nbsp;&nbsp;&nbsp;&nbsp;整体改造&nbsp;&nbsp;&nbsp;&nbsp;提升形象</div>
        </li>
        <li>
          <div class="operate-img">
            <img src="../../assets/PC/index/home_icon_2.png" alt />
          </div>
          <div class="operate-content">品牌赋值，渠道共用，推广轻松</div>
        </li>
        <li>
          <div class="operate-img">
            <img src="../../assets/PC/index/home_icon_3.png" alt />
          </div>
          <div class="operate-content">精英团队，管理专业，一站式服务</div>
        </li>
      </ul>
    </div>
    <!-- E operate -->

    <!-- S system -->
    <div class="home-system">
      <div class="home-system-cell">
        <el-cell message="功能强大系统"></el-cell>
      </div>
      <div class="home-system-body">
        <div class="home-system-body-bottom home-system-body-cell">
          <img src="../../assets/PC/index/home_icon_4.png" alt />
          <div class="home-system-body-cell-summary">
            酒店、公
            寓双模式
          </div>
          <div class="home-system-body-cell-content">系统兼顾酒店PMS系统以及传统的公寓租赁模式，实现一个系统适用全市场场景</div>
        </div>
        <div class="home-system-body-top home-system-body-cell">
          <img src="../../assets/PC/index/home_icon_5.png" alt />
          <div class="home-system-body-cell-summary">
            全自动抄
            表、催租
          </div>
          <div class="home-system-body-cell-content">兼容市面上大多数智能设备，能实现自动抄表，催租，同时多款智能设备实现联动式管控</div>
        </div>
        <div class="home-system-body-bottom home-system-body-cell">
          <img src="../../assets/PC/index/home_icon_6.png" alt />
          <div class="home-system-body-cell-summary">
            财务报表
            清晰明了
          </div>
          <div class="home-system-body-cell-content">日租、月租报表清晰，应收、未收、实收清楚了解财务状况</div>
        </div>
        <div class="home-system-body-top home-system-body-cell">
          <img src="../../assets/PC/index/home_icon_7.png" alt />
          <div class="home-system-body-cell-summary">
            线上收款
            分秒到账
          </div>
          <div class="home-system-body-cell-content">线上缴费、收支便捷，支持微信、支付宝、银联、信用卡等，提现指定账户</div>
        </div>
        <div class="home-system-body-bottom home-system-body-cell" style="margin-right:0">
          <img src="../../assets/PC/index/home_icon_8.png" alt />
          <div class="home-system-body-cell-summary">
            操作简单
            一看就会
          </div>
          <div class="home-system-body-cell-content">界面简单，哪里不会点哪里。操作指导文档随时可查，在线客服随时候命</div>
        </div>
        <img src="../../assets/PC/index/home_icon_9.png" alt class="home-system-body-bolang" />
      </div>
    </div>
    <!-- E system -->

    <!-- S hard -->
    <div class="home-hard">
      <div class="home-hard-cell">
        <el-cell message="世纪难题"></el-cell>
      </div>
      <div class="home-hard-body">
        <div class="home-hard-body-content">
          <div class="home-hard-body-title" style="color: #07BAC6;text-align: end;">优E家</div>
          <div class="home-hard-body-text">
            <div class="clear">
              <div class="home-hard-body-text-title">资金扶持</div>
            </div>
            <div class="home-hard-body-text-content">提供资金扶持方案，解决资金之忧</div>
            <div class="clear">
              <div class="home-hard-body-text-title">品牌赋能</div>
            </div>

            <div class="home-hard-body-text-content">品牌扶持，多平台推广，共享会员</div>
            <div class="clear">
              <div class="home-hard-body-text-title">专业团队</div>
            </div>

            <div class="home-hard-body-text-content">智能化管理，及时回应租客需求</div>
            <div class="clear">
              <div class="home-hard-body-text-title">优质体验</div>
            </div>

            <div class="home-hard-body-text-content">风格独特，别具匠心，极致体验</div>
            <div class="clear">
              <div class="home-hard-body-text-title">合作共赢</div>
            </div>
            <div class="home-hard-body-text-content">企业和个人的多边合作，携手共进</div>
          </div>
        </div>

        <div class="home-hard-body-vs-tips">
          <div>VS</div>
          <div>VS</div>
          <div>VS</div>
          <div>VS</div>
          <div>VS</div>
          <div>VS</div>
        </div>

        <div>
          <div class="home-hard-body-title" style="color: #999999;">其他家</div>
          <div class="home-hard-body-other">
            <div class="home-hard-body-other-title">资本重</div>
            <div class="home-hard-body-other-content">改造贵、运作资金短缺</div>
            <div class="home-hard-body-other-title">招租难</div>
            <div class="home-hard-body-other-content">知名度不搞，渠道单一，曝光率低</div>
            <div class="home-hard-body-other-title">管理烦</div>
            <div class="home-hard-body-other-content">水电抄表，催收难，维修工作成日常</div>
            <div class="home-hard-body-other-title">内卷残</div>
            <div class="home-hard-body-other-content">同行竞争激烈，劣币驱逐良币</div>
            <div class="home-hard-body-other-title">扩大忧</div>
            <div class="home-hard-body-other-content">规格局限，房源难寻，团队腐化</div>
          </div>
        </div>
      </div>
    </div>
    <!-- E hard -->

    <!-- S success -->
    <div class="home-success">
      <div class="home-success-body">
        <el-cell message="成功案例"></el-cell>
        <div class="home-success-body-img">
          <div class="home-success-body-img-div">
            <el-imgarea tradeName="东坑店" :url="urlone" :mydata="testMessage"></el-imgarea>
          </div>
          <div class="home-success-body-img-div">
            <el-imgarea tradeName="横坑店" :url="urlTwo" :mydata="testMessageTwo"></el-imgarea>
          </div>
          <div class="home-success-body-img-div">
            <el-imgarea tradeName="松湖店" :url="urlThree" :mydata="testMessageThree"></el-imgarea>
          </div>
        </div>
      </div>
    </div>
    <!-- E success -->
    <el-footer></el-footer>
  </div>
</template>

<script>
import elHead from "../../components/PC/head";
import elCell from "../../components/PC/cell";
import elImgarea from "../../components/PC/imgarea";
import elFooter from "../../components/PC/footer";
export default {
  name: "Home",
  components: {
    elHead,
    elCell,
    elImgarea,
    elFooter
  },
  data() {
    return {
      testMessage:
        "位于东莞市东坑镇东安路396号，总建筑面积约25000㎡，提供人才公寓434套。针对项目周边企业单位白领、产业园蓝领等青年人才的居住需求设有精致单间、一房一厅、以及两房一厅等多种户型。楼下吃喝玩乐一条街，年均入住率不低于93%",
      urlone: require("../../assets/PC/index/housing_1.jpg"),
      urlTwo: require("../../assets/PC/index/housing_2.jpg"),
      urlThree: require("../../assets/PC/index/housing_3.jpg"),
      testMessageTwo:
        "位于东莞市寮步镇横坑横塘路16号，总建筑面积约7300㎡，提供精品房95套，打造新享乐式的长租公寓，供有充足的停车位，方正的户型，提供齐全的家具设备，已然成为周边新兴公寓的标杆，年均入住率不低于96%",
      testMessageThree:
        "位于东莞市大朗镇富民北路68号，总建筑面积约10000㎡，提供优质房257套，公寓房间提供完备的配套设施，公区拥有全新的健身器材，专用的停车场，临近大朗汽车站交通毋庸置疑，商圈中心生活丰富多彩，年均入住率不低于91%"
    };
  }
};
</script>

<style>
.home {
  width: 100%;
  height: 100%;
  overflow-x: clip;
}
.home-body {
  width: 100%;
  height: 100%;
  margin-bottom: 100px;
}

.home-body img {
  width: 100%;
  height: calc(100vh);
}

/* operate */
.home-operate {
  height: 720px;
}

.operate-body {
  margin-top: 72px;
  display: flex;
  justify-content: center;
}
.operate-body li {
  list-style-type: none;
  margin-right: 32px;
  width: 378px;
  height: 332px;
  background: #ffffff;
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.operate-img {
  padding: 32px 36px;
}
.operate-img img {
  width: 306px;
  height: 214px;
}

.operate-content {
  text-align: center;
}

/* system */

.home-system {
  height: 760px;
  background-color: #f2f3f5;
}

.home-system-cell {
  margin-top: 100px;
}

.home-system-body {
  margin-top: 88px;
  position: relative;
  display: flex;
  padding: 0 360px;
}

.home-system-body-cell {
  width: 214px;
  height: 287px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 2px rgba(7, 186, 198, 0.15);
  position: relative;
  margin-right: 32px;
  padding: 32px 16px 36px 16px;
  text-align: center;
}

.home-system-body-cell:after {
  content: "";
  border: 10px solid transparent;
  border-bottom-color: white;
  position: absolute;
  left: 95px;
  margin-top: -19px;
  bottom: -20px;
  transform: rotate(180deg);
}

.home-system-body-bottom {
  margin-top: 80px;
}

.home-system-body-bolang {
  width: 1200px;
  height: 84px;
  position: absolute;
  bottom: -69px;
}

.home-system-body-cell img {
  width: 72px;
  height: 72px;
}

.home-system-body-cell-summary {
  width: 65px;
  font-size: 16px;
  text-align: center;
  margin: 16px auto 0 auto;
}

.home-system-body-cell-content {
  font-weight: 400;
  color: #565656;
  font-size: 14px;
  margin-top: 14px;
}

/* Hard */
.home-hard {
  height: 966px;
}

.home-hard-cell {
  margin-top: 100px;
}

.home-hard-body {
  margin-top: 77px;
  display: flex;
  justify-content: center;
}

.home-hard-body-title {
  font-size: 36px;
  margin-bottom: 28px;
}

.home-hard-body-text {
  width: 420px;
  height: 584px;
  background: #07bac6;
  border-radius: 100px 0px 60px 0px;
}

.home-hard-body-text {
  text-align: end;
  overflow: hidden;
}

.home-hard-body-text-title {
  width: 136px;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  font-size: 26px;
  color: #07bac6;
  line-height: 40px;
  text-align: center;
  float: right;
}

.clear {
  clear: both;
  height: 40px;
  margin-top: 30px;
  margin-right: 32px;
}

.home-hard-body-text-content {
  font-size: 20px;
  color: #ffffff;
  margin: 16px 32px 0 0;
}

.home-hard-body-vs-tips {
  margin: 0 50px;
}

.home-hard-body-vs-tips div {
  font-size: 24px;
  font-family: Sathu;
  color: #ffffff;
  line-height: 40px;
  width: 40px;
  height: 40px;
  background: #07bac6;
  border-radius: 20px;
  text-align: center;
  margin-bottom: 70px;
  position: relative;
}

.home-hard-body-vs-tips div::after {
  content: "";
  position: absolute;
  height: 70px;
  width: 1px;
  background: #07bac6;
  left: 20px;
  bottom: -70px;
}

.home-hard-body-other {
  width: 420px;
  height: 584px;
  background: #e1eded;
  border-radius: 0px 100px 0px 60px;
  overflow: hidden;
}

.home-hard-body-other-title {
  margin: 30px 0 0 32px;
  width: 108px;
  height: 40px;
  background: #fba74f;
  border-radius: 20px;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
}

.home-hard-body-other-content {
  margin: 16px 0 0 32px;
  color: #565656;
  font-size: 20px;
}

.home-success {
  height: 900px;
  background-color: #f2f3f5;
}

.home-success-body {
  padding: 90px 0 0 0;
}

.home-success-body-img {
  margin: 72px 0 0 0;
  display: flex;
  justify-content: center;
}

.home-success-body-img-div {
  margin-right: 32px;
}
</style>
  
