<template>
  <div class="about">
    <!-- S head -->
    <el-head :index="false" chooseRooter="about"></el-head>
    <!-- E head -->

    <!-- S banner -->
    <div class="about-banner">
      <img src="../../assets/PC/about/banner_10.png" alt />
    </div>
    <!-- E banner -->

    <!-- S introduction -->
    <div class="about-introduction">
      <div class="about-introduction-title">公司简介</div>
      <div class="about-introduction-content">
        <div
          class="about-introduction-content-text"
        >东莞市乐链信息科技有限公司，是一家专注于科技技术研发的国家高新技术企业。拥有互联网平台（APP、微信小程序、网站）、优e家公寓智能设备、优e家酒店公寓等多个项目。公司在在各类智能硬件以及软件技术开发应用方面积累了丰富经验，利用大数据与云计算技术、综合布线技术、安全防范技术、自动控制技术等将家居生活有关的设施集成，构建高效的智能家居设备与智能管理系统，并推出了成熟的软硬件深度一体化解决方案，通过立足于民宿最密集的珠三角市场，帮助传统民宿生态链的转型升级。为业主解决管理和提租的困难，为租客提供优质住房服务和便捷的生活服务。</div>
        <div class="about-introduction-content-img">
          <img src="../../assets/PC/about/logo_2.png" alt />
        </div>
      </div>
    </div>
    <!-- E introduction -->

    <!-- S history -->
    <div class="about-history">
      <el-cell message="公司历程"></el-cell>

      <div class="about-history-progress">
        <div class="about-history-progress-line">
          <div class="line"></div>
          <div class="dian choose">
            2021
            <div></div>
          </div>
          <div class="dian">2020</div>
          <div class="dian">2019</div>
          <div class="dian">2018</div>
        </div>
        <div class="about-history-progress-box">
          <div class="about-history-progress-describe">
            <div class="about-history-progress-describe-title">2021年</div>
            <div class="about-history-progress-describe-mouth">8月</div>
            <div class="about-history-progress-describe-text">公司成为东莞集中式长租公寓房源管理领域第一</div>
            <div class="about-history-progress-describe-mouth">5月</div>
            <div class="about-history-progress-describe-text">携手优e家酒店式公寓，实现东莞全面布局</div>
          </div>
          <div class="about-history-progress-describe">
            <div class="about-history-progress-describe-title">2020年</div>
            <div class="about-history-progress-describe-mouth">8月</div>
            <div class="about-history-progress-describe-text">确立打造“成为中国一流的物业运营商”的目标和发展战略</div>
          </div>
          <div class="about-history-progress-describe">
            <div class="about-history-progress-describe-title">2019年</div>
            <div class="about-history-progress-describe-mouth">8月</div>
            <div class="about-history-progress-describe-text">全面布局租赁产业链，实现战略领先</div>
          </div>
        </div>
      </div>
    </div>
    <!-- E history -->

    <!-- S team -->
    <div class="about-team">
      <el-cell message="团队风采"></el-cell>

      <div class="about-team-people">
        <div class="about-team-people-box">
          <img src="../../assets/PC/about/dengjie.jpg" alt />
          <div class="about-team-people-box-text">
            <div class="about-team-people-box-text-title">邓婕</div>
            <div class="about-team-people-box-text-identity">优e家创始人</div>
            <div
              class="about-team-people-box-text-content"
            >主 负责战略制定及 执行、财务管理 及资产证券化领 域</div>
          </div>
        </div>
        <div class="about-team-people-box">
          <img src="../../assets/PC/about/chensansu.png" alt />
          <div class="about-team-people-box-text">
            <div class="about-team-people-box-text-title">陈三苏</div>
            <div class="about-team-people-box-text-identity">总经理</div>
            <div
              class="about-team-people-box-text-content"
            >专业政府及工程领域， 主负责协调发展政府 资源、市场营销管理 及业务资源发展</div>
          </div>
        </div>
      </div>
    </div>
    <!-- E team -->

    <!-- S img -->
    <div class="about-img">
      <div class="first">
        <img src="../../assets/PC/about/about_1.jpg" alt />
      </div>
      <div class="third">
        <img src="../../assets/PC/about/about_2.jpg" alt />
      </div>
      <div class="third">
        <img src="../../assets/PC/about/about_3.jpg" alt />
      </div>
      <div class="third">
        <img src="../../assets/PC/about/about_4.jpg" alt />
      </div>
      <div class="second">
        <img src="../../assets/PC/about/about_5.jpg" alt />
      </div>
      <div class="second">
        <img src="../../assets/PC/about/about_6.jpg" alt />
      </div>
      <div class="second">
        <img src="../../assets/PC/about/about_7.jpg" alt />
      </div>
      <div class="second">
        <img src="../../assets/PC/about/about_8.jpg" alt />
      </div>
    </div>
    <!-- E img -->

    <!-- footer -->
    <el-footer></el-footer>
    <!-- footer -->
  </div>
</template>

<script>
import elHead from "../../components/PC/head";
import elCell from "../../components/PC/cell";
import elFooter from "../../components/PC/footer";
export default {
  name: "cooperation",
  components: {
    elHead,
    elCell,
    elFooter
  }
};
</script>

<style>
.about {
  width: 100%;
  padding-top: 72px;
}

.about-banner {
  width: 100%;
  height: 500px;
}

.about-banner img {
  width: 100%;
  height: 100%;
}

.about-introduction {
  width: 1092px;
  height: 300px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 2px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 100px 40px;
  margin: 96px auto 0 auto;
}

.about-introduction-title {
  font-size: 36px;
  color: #222222;
}

.about-introduction-content {
  margin-top: 22px;
  display: flex;
  justify-content: space-between;
}

.about-introduction-content-text {
  width: 632px;
  line-height: 32px;
}

.about-introduction-content-img img {
  width: 365px;
  height: 118px;
  margin-top: 32px;
}

.about-history {
  margin-top: 98px;
  background-color: #f2f3f5;
  padding-bottom: 225px;
}

.about-history-progress {
  margin: 120px auto 0 auto;
  width: 1200px;
}

.about-history-progress-line {
  width: 600px;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 28px;
  color: #07bac6;
  padding: 0 300px;
}

.line {
  width: 1200px;
  height: 2px;
  background-color: rgba(7, 186, 198, 0.6);
  position: absolute;
  bottom: -40px;
  left: 0;
}

.dian {
  position: relative;
}

.dian::after {
  content: "";
  position: absolute;
  bottom: -43px;
  width: 8px;
  left: 32px;
  height: 8px;
  border-radius: 100%;
  background-color: rgba(7, 186, 198, 0.6);
}

.about-history-progress-line .choose {
  font-size: 36px;
}

.choose div {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid rgba(7, 186, 198, 0.6);
  left: 26px;
  bottom: -49px;
}

.about-history-progress-describe {
  position: relative;
  width: 340px;
}

.about-history-progress-describe-title {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  position: relative;
}

.about-history-progress-describe-title::after {
  content: "";
  width: 18px;
  height: 4px;
  background: #07bac6;
  border-radius: 1px;
  position: absolute;
  bottom: -10px;
  left: 0;
}
.about-history-progress-describe-mouth {
  margin-top: 23px;
  font-weight: 600;
}

.about-history-progress-describe-text {
  margin-top: 6px;
}

.about-team-people {
  width: 1200px;
  margin: 72px auto 0 auto;
  display: flex;
  justify-content: space-between;
}

.about-team-people-box {
  width: 583px;
  height: 272px;
  background-color: #f2f3f5;
  display: flex;
}

.about-team-people-box img {
  width: 208px;
  height: 100%;
}

.about-team-people-box-text {
  padding: 20px 20px;
  font-size: 14px;
}

.about-team-people-box-text-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 6px;
}

.about-team-people-box-text-identity {
  margin-bottom: 20px;
}

.about-img {
  margin: 90px auto 0 auto;
  width: 1200px;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  height: 640px;
}

.about-img img {
  width: 100%;
  height: 100%;
}

.first {
  width: 408px;
  height: 288px;
}

.second {
  width: 288px;
  height: 288px;
}

.third {
  width: 248px;
  height: 288px;
}

.about-history-progress-box {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  margin: 64px auto 0 auto;
}
</style>
  
