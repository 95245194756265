<template>
  <div class="footer">
    <div class="footer-body">
      <div class="footer-serve">
        <div class="footer-serve-title">18002675029</div>
        <div class="footer-serve-content">服务热线</div>
      </div>
      <div class="footer-product">
        <div>
          <div class="footer-product-title">产品与服务</div>
          <div class="footer-product-content">管家系统</div>
          <div class="footer-product-content">硬件产品</div>
        </div>
        <div>
          <div class="footer-product-title">物业合作</div>
          <div class="footer-product-content">物业要求</div>
          <div class="footer-product-content">市场份额</div>
        </div>
        <div>
          <div class="footer-product-title">关于我们</div>
          <div class="footer-product-content">公司简介</div>
          <div class="footer-product-content">公司历程</div>
          <div class="footer-product-content">团队风采</div>
        </div>
      </div>
      <div class="footer-img">
        <div>
          <img src="../../assets/PC/footer/qr_1.png" alt />
          <div>企业号</div>
        </div>
        <div>
          <img src="../../assets/PC/footer/qr_2.png" alt />
          <div>公众号</div>
        </div>
        <div>
          <img src="../../assets/PC/footer/qr_3.png" alt />
          <div>小程序</div>
        </div>
      </div>

      <a class="footer-record" href="https://beian.miit.gov.cn/">
        <img src="../../assets/PC/footer/beian.png" alt />粤ICP备18093380号
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer"
};
</script>

<style>
.footer {
  width: 100%;
  height: 300px;
  background: #161e31;
  color: #ffffff;
  overflow: hidden;
}

.footer-body {
  width: 1200px;
  display: flex;
  margin: 43px auto 59px auto;
  justify-content: space-between;
  border-bottom: 1px solid #737883;
  position: relative;
}

.footer-serve-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}

.footer-serve-content {
  font-size: 14px;
  font-weight: 400;
}

.footer-product {
  width: 450px;
  display: flex;
  justify-content: space-between;
}

.footer-product-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 32px;
}

.footer-product-content {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 18px;
}

.footer-img {
  display: flex;
  justify-content: space-between;
  width: 450px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}

.footer-img img {
  width: 112px;
  height: 112px;
  margin-bottom: 18px;
}

.footer-record {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  bottom: -50px;
  left: 525px;
  cursor: pointer;
  color: #ffffff !important;
}
.footer-record img {
  width: 18px;
  height: 20px;
  margin-top: 5px;
}

.footer-record {
  text-decoration: none;
  color: #ffffff !important;
}
/*正常的未被访问过的链接*/
.footer-record:link {
  text-decoration: none;
  color: #ffffff !important;
}
/*已经访问过的链接*/
.footer-record:visited {
  text-decoration: none;
  color: #ffffff !important;
}
/*鼠标划过(停留)的链接*/
.footer-record:hover {
  text-decoration: none;
  color: #ffffff !important;
}
/* 正在点击的链接*/
.footer-record:active {
  text-decoration: none;
  color: #ffffff !important;
}
.footer-record:-webkit-any-link {
  color: #ffffff !important;
}
</style>
  
