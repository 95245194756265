<template>
  <!-- 主题色cell -->
  <div class="cell">
      <div class="cell-title">{{message}}</div>
  </div>
</template>

<script>
export default {
  name: "cell",
  props: {
      message: String
  }
};
</script>

<style>
.cell {
  display: flex;
}
.cell-title {
  margin: 0 auto;
  margin-top: 100px;
  position: relative;
  font-size: 42px;
  font-weight: 600;
}

.cell-title::after {
  position: absolute;
  bottom: 0;
  content: "";
  width: 100%;
  height: 12px;
  background-color: #07bac6;
  left: 0;
  opacity: 0.4;
}
</style>
  
