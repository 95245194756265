<template>
  <div class="example">
    <!-- S head -->
    <el-head :index="false" chooseRooter="example"></el-head>
    <!-- E head -->

    <!-- S banner -->
    <div class="example-banner">
      <img src="../../assets/PC/example/banner_9.png" alt />
    </div>
    <!-- E banner -->

    <!-- S white -->
    <div class="example-white">
      <div class="example-white-body">
        <div class="example-white-body-content">
          <div class="example-white-body-content-title">东坑店</div>
          <div
            class="example-white-body-content-text"
          >位于东莞市东坑镇东安路396号，总建筑面积约25000㎡，提供人才公寓434套。针对项目周边企业单位白领、产业园蓝领等青年人才的居住需求设有精致单间、一房一厅、以及两房一厅等多种户型。楼下吃喝玩乐一条街，年均入住率不低于93%</div>
          <div class="example-white-body-content-icon">周边配置</div>
          <div class="example-white-body-content-tips">
            <div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_1.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">商业服务</span>
                  <span
                    class="example-white-body-content-tips-area-index-introduce"
                  >庆记商业街、展达世纪广场、东华商业广场</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_2.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">医疗安保</span>
                  <span
                    class="example-white-body-content-tips-area-index-introduce"
                  >东坑镇社区卫生服务中心、东坑派出所</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_3.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">生活配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">湘阴百货、宜家百货、福天便利店</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_4.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">交通配套</span>
                  <span
                    class="example-white-body-content-tips-area-index-introduce"
                  >东安路主路、海东学校公交站、长安塘加油站公交站、江坑楼站</span>
                </div>
              </div>
            </div>

            <div class="map-box">
              <amap :zoom="15" :center="[113.957603, 22.987419]">
                <amap-marker :position="[113.957603, 22.987419]" />
              </amap>
            </div>
          </div>
        </div>

        <div class="example-white-body-img-box">
          <swiper :options="swiperOption">
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_1.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_2.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_3.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_4.jpg" alt />
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination" slot="pagination"></div>
        </div>
      </div>
    </div>
    <!-- E white -->

    <div class="example-white black">
      <div class="example-white-body">
        <div class="example-white-body-img-box">
          <swiper :options="swiperOption1">
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_5.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_6.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_7.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_8.jpg" alt />
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination-1" slot="pagination"></div>
        </div>
        <div class="example-white-body-content">
          <div class="example-white-body-content-title">横坑店</div>
          <div
            class="example-white-body-content-text"
          >位于东莞市寮步镇横坑横塘路16号，总建筑面积约7300㎡，提供精品房95套，打造新享乐式的长租公寓，供有充足的停车位，方正的户型，提供齐全的家具设备，已然成为周边新兴公寓的标杆，年均入住率不低于96%</div>
          <div class="example-white-body-content-icon">周边配置</div>
          <div class="example-white-body-content-tips">
            <div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_1.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">商业服务</span>
                  <span class="example-white-body-content-tips-area-index-introduce">新太佳购物广场、星河城、衡泰美食广场，三和商业广场</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_2.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">医疗安保</span>
                  <span class="example-white-body-content-tips-area-index-introduce">横坑派出所、光华医院</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_3.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">生活配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">
                    佳宜百货、美宜佳、福民百货
                  </span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_4.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">交通配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">
                    石井支路口公交站、禾合田公交站、汽车东站
                  </span>
                </div>
              </div>
            </div>

            <div class="map-box">
              <amap :zoom="15" :center="[113.757469, 23.054661]">
                <amap-marker :position="[113.757469, 23.054661]" />
              </amap>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="example-white">
      <div class="example-white-body">
        <div class="example-white-body-content">
          <div class="example-white-body-content-title">松湖店</div>
          <div
            class="example-white-body-content-text"
          >位于东莞市大朗镇富民北路68号，总建筑面积约10000㎡，提供优质房257套，公寓房间提供完备的配套设施，公区拥有全新的健身器材，专用的停车场，临近大朗汽车站交通毋庸置疑，商圈中心生活丰富多彩，年均入住率不低于91%</div>
          <div class="example-white-body-content-icon">周边配置</div>
          <div class="example-white-body-content-tips">
            <div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_1.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">商业服务</span>
                  <span class="example-white-body-content-tips-area-index-introduce">华为科技园区、漫步者园区、巷头毛织文化公园</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_2.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">医疗安保</span>
                  <span class="example-white-body-content-tips-area-index-introduce">大朗公安局分局、东莞南华妇科医院、大朗派出所巷头警务室</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_3.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">生活配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">
                    真功夫、美宜佳、家家幸福超市、上好便利店
                  </span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_4.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">交通配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">
                    大朗汽车客运总站、大朗车站公交站
                  </span>
                </div>
              </div>
            </div>

            <div class="map-box">
              <amap :zoom="15" :center="[113.920547, 22.966681]">
                <amap-marker :position="[113.920547, 22.966681]" />
              </amap>
            </div>
          </div>
        </div>

        <div class="example-white-body-img-box">
          <swiper :options="swiperOption2">
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_9.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_10.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_11.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_12.jpg" alt />
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination-2" slot="pagination"></div>
        </div>
      </div>
    </div>

    <div class="example-white black">
      <div class="example-white-body">
        <div class="example-white-body-img-box">
          <swiper :options="swiperOption3">
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_13.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_14.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_15.jpg" alt />
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="example-white-body-img">
                <img src="../../assets/PC/example/img_16.jpg" alt />
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination-3" slot="pagination"></div>
        </div>
        <div class="example-white-body-content">
          <div class="example-white-body-content-title">祥禧店</div>
          <div
            class="example-white-body-content-text"
          >位于东莞市寮步镇宝塘路，总建筑面积约4000㎡，提供多户型精品房58套，温馨的装修风格，为用户提供了多样的选择性，环境静谧，装修全新，房间设施齐全，为情侣打造爱的巢穴，为家庭建造温馨的港湾，年均入住率不低于98%</div>
          <div class="example-white-body-content-icon">周边配置</div>
          <div class="example-white-body-content-tips">
            <div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_1.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">商业服务</span>
                  <span class="example-white-body-content-tips-area-index-introduce">新太佳购物广场、星河城、衡泰美食广场，百横五金建材市场</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_2.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">医疗安保</span>
                  <span class="example-white-body-content-tips-area-index-introduce">横坑派出所、光华医院</span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_3.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">生活配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">
                    源兴百货店、一家人便利店、三龙便利店
                  </span>
                </div>
              </div>
              <div class="example-white-body-content-tips-area">
                <div class="example-white-body-content-tips-area-index">
                  <img src="../../assets/PC/example/case_icon_4.png" alt />
                  <span class="example-white-body-content-tips-area-index-icon">交通配套</span>
                  <span class="example-white-body-content-tips-area-index-introduce">
                    禾合田公交站、汽车东站
                  </span>
                </div>
              </div>
            </div>

            <div class="map-box">
              <amap :zoom="15" :center="[113.877632, 23.003839]">
                <amap-marker :position="[113.877632, 23.003839]" />
              </amap>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- S Exhibition -->
    <div class="example-exhibition">
      <el-cell message="门店展示"></el-cell>

      <div class="example-exhibition-body">
        <div class="first">
          <img src="../../assets/PC/example/img_17.jpg" alt />
        </div>
        <div class="third">
          <img src="../../assets/PC/example/img_18.jpg" alt />
        </div>
        <div class="third">
          <img src="../../assets/PC/example/img_19.jpg" alt />
        </div>
        <div class="third">
          <img src="../../assets/PC/example/img_20.jpg" alt />
        </div>
        <div class="second">
          <img src="../../assets/PC/example/img_21.jpg" alt />
        </div>
        <div class="second">
          <img src="../../assets/PC/example/img_22.jpg" alt />
        </div>
        <div class="second">
          <img src="../../assets/PC/example/img_23.jpg" alt />
        </div>
        <div class="second">
          <img src="../../assets/PC/example/img_24.jpg" alt />
        </div>
      </div>
    </div>
    <!-- E Exhibition -->
    <el-footer></el-footer>
  </div>
</template>

<script>
import elFooter from "../../components/PC/footer";
import elHead from "../../components/PC/head";
import elCell from "../../components/PC/cell";
export default {
  name: "example",
  components: {
    elHead,
    elCell,
    elFooter
  },
  data() {
    return {
      swiperOption: {
        //显示分页
        pagination: {
          el: ".swiper-pagination"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true,
        realIndex: this.myindex
      },
      swiperOption1: {
        //显示分页
        pagination: {
          el: ".swiper-pagination-1"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true,
        realIndex: this.myindex
      },
      swiperOption2: {
        //显示分页
        pagination: {
          el: ".swiper-pagination-2"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true,
        realIndex: this.myindex
      },
      swiperOption3: {
        //显示分页
        pagination: {
          el: ".swiper-pagination-3"
        },
        //设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        //开启循环模式
        loop: true,
        realIndex: this.myindex
      }
    };
  }
};
</script>

<style>
.example {
  width: 100%;
  height: 100%;
  padding-top: 72px;
}

.example-banner {
  width: 100%;
}

.example-banner img {
  height: 500px;
  width: 100%;
}

.example-white {
  background-color: #ffffff;
  height: 620px;
}

.example-white-body {
  width: 1200px;
  margin: 120px auto 0 auto;
  display: flex;
  justify-content: space-between;
}

.example-white-body-content {
  width: 527px;
}

.example-white-body-content-title {
  color: #222222;
  font-size: 32px;
  margin-bottom: 19px;
}

.example-white-body-content-text {
  margin-bottom: 36px;
}

.example-white-body-content-icon {
  font-weight: 600;
  margin-bottom: 16px;
}

.example-white-body-content-tips {
  display: flex;
}

.example-white-body-content-tips-area-index {
  display: flex;
}

.example-white-body-content-tips-area-index img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}

.example-white-body-content-tips-area-index-icon {
  color: #222222;
  font-size: 14px;
  width: 68px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background: #e8e8e8;
}

.example-white-body-content-tips-area-index-introduce {
  margin-left: 10px;
  color: #565656;
  font-size: 14px;
  max-width: 196px;
}

.example-white-body-content-tips-area {
  margin-bottom: 10px;
}

.example-white-body-img {
  width: 500px;
  height: 334px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  margin: 20px;
}

.example-white-body-img img {
  width: 500px;
  height: 334px;
}

.black {
  background-color: #f2f3f5 !important;
  padding-top: 120px;
}

.black .example-white-body {
  margin: 0 auto;
}

.example-exhibition-body {
  margin: 72px auto 0 auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
  height: 610px;
  flex-wrap: wrap;
}

.example-exhibition-body img {
  width: 100%;
  height: 100%;
}

.first {
  width: 408px;
  height: 288px;
}

.second {
  width: 288px;
  height: 288px;
}

.third {
  width: 248px;
  height: 288px;
}

.example-white-body-img-box {
  width: 580px;
  height: 450px;
  overflow: hidden;
}

.example .example-white-body-img-box .swiper-pagination-bullet-active {
  width: 16px;
  height: 8px;
  background: #07bac6;
  border-radius: 4px;
  opacity: 1;
}

.example .swiper-pagination-bullet {
  margin-right: 8px;
  background: #07bac6;
  opacity: 0.25;
}

.swiper-pagination-1 {
  margin-left: 400px;
}

.swiper-pagination-2 {
  margin-left: 100px;
}

.swiper-pagination-3 {
  margin-left: 400px;
}

.map-box {
  width: 194px;
  height: 136px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.15);
  margin: 0 20px;
}

.map-box img {
  width: auto;
  height: auto;
}
</style>
  
