import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pc/Home.vue'
import serve from '../views/pc/serve.vue'
import cooperation from '../views/pc/cooperation.vue'
import example from '../views/pc/example.vue'
import about from '../views/pc/about.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/serve',
    name: 'serve',
    component: serve
  },
  {
    path: "/cooperation",
    name: 'cooperation',
    component: cooperation
  },
  {
    path: '/example',
    name: 'example',
    component: example
  }, {
    path: '/about',
    name: 'about',
    component: about
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
