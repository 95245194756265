<template>
  <!-- 公寓区域介绍 -->
  <div class="imgarea">
    <div class="imgarea-img">
      <img :src="url" />
    </div>
    <div class="imgarea-title">{{tradeName}}</div>
    <div class="imgarea-area">{{mydata}}</div>
  </div>
</template>

<script>
export default {
  name: "imgarea",
  props: {
    mydata: String,
    tradeName: String,
    url: String
  }
};
</script>

<style>
.imgarea {
  width: 338px;
  height: 356px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  padding: 16px 16px 24px 16px;
}

.imgarea-img img {
  width: 338px;
  height: 232px;
}

.imgarea-title {
  margin-top: 12px;
  font-size: 16px;
  font-weight: 600;
}

.imgarea-area {
  margin-top: 16px;
  font-weight: 400;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
</style>
  
