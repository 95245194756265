<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style>
</style>

<script>
export default {
  name: "app",
  mounted() {
    if (this._isMobile()) {
      console.log("user is using mobile");
      // this.$router.replace("/m_index");
    } else {
      console.log("user is using PC");
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    }
  }
};
</script>
<style>
#app {
  width: 100%;
  height: 100vh;
}
body {
  margin: 0;
  padding: 0;
}
</style>