<template>
  <div class="cooperation">
    <el-head :index="false" chooseRooter="cooperation"></el-head>
    <div class="cooperation-banner">
      <img src="../../assets/PC/cooperation/banner_8.png" alt />
    </div>
    <div class="cooperations-layout">
      <el-cell message="市场布局"></el-cell>
      <div class="cooperations-layout-body">
        <div class="cooperations-layout-body-content">
          <img src="../../assets/PC/cooperation/property_icon_1.png" alt />
          <div class="cooperations-layout-body-content-title">健康稳定的回报</div>
          <div class="cooperations-layout-body-content-text">每月固定的保底资金到账，房源出租率，三方兜底</div>
        </div>
        <div class="cooperations-layout-body-content">
          <img src="../../assets/PC/cooperation/property_icon_2.png" alt />
          <div class="cooperations-layout-body-content-title">全方位管控</div>
          <div class="cooperations-layout-body-content-text">
            股东、房东都有权限，实时
            查看房间出租，及财务状况
          </div>
        </div>
        <div class="cooperations-layout-body-content">
          <img src="../../assets/PC/cooperation/property_icon_3.png" alt />
          <div class="cooperations-layout-body-content-title">获客能力强</div>
          <div class="cooperations-layout-body-content-text">
            品牌影响力，集团渠道，多
            方位获客，会员共享
          </div>
        </div>
        <div class="cooperations-layout-body-content">
          <img src="../../assets/PC/cooperation/property_icon_4.png" alt />
          <div class="cooperations-layout-body-content-title">最优资源配置</div>
          <div class="cooperations-layout-body-content-text">
            完整供应链，实现效益最
            大化，已最小的成本得最
            高的效益
          </div>
        </div>
      </div>
    </div>

    <div class="cooperations-market">
      <el-cell message="市场份额"></el-cell>

      <div class="cooperations-Statistics">
        <div class="cooperations-Statistics-cell">
          <img src="../../assets/PC/cooperation/property_icon_market_1.png" alt />
          <div>
            门店
            <span>20</span>家
          </div>
        </div>
        <div class="cooperations-Statistics-cell">
          <img src="../../assets/PC/cooperation/property_icon_market_2.png" alt />
          <div>
            房间数
            <span>5000</span>间
          </div>
        </div>
        <div class="cooperations-Statistics-cell">
          <img src="../../assets/PC/cooperation/property_icon_market_3.png" alt />
          <div>
            镇区
            <span>16</span>个
          </div>
        </div>
        <div class="cooperations-Statistics-cell">
          <img src="../../assets/PC/cooperation/property_icon_market_4.png" alt />
          <div>
            出租率
            <span>96%</span>
          </div>
        </div>
      </div>

      <div class="cooperations-Statistics-img">
        <div id="mapChart" class="dg-map"></div>
      </div>
    </div>

    <div class="cooperations-property">
      <el-cell message="物业标准"></el-cell>

      <div class="cooperations-property-body">
        <div class="cooperations-property-body-cell">
          <div class="cooperations-property-body-cell-img">
            <img src="../../assets/PC/cooperation/property_icon_5.png" alt />
          </div>
          <div class="cooperations-property-body-cell-text">
            <div class="cooperations-property-body-cell-text-title">产权清晰，证件齐全</div>
            <div
              class="cooperations-property-body-cell-text-content"
            >房产证，土地证，开发商或者集体房的重要凭证，住宅质量保证书，住宅使用说明书</div>
          </div>
        </div>
        <div class="cooperations-property-body-cell">
          <div class="cooperations-property-body-cell-img">
            <img src="../../assets/PC/cooperation/property_icon_6.png" alt />
          </div>
          <div class="cooperations-property-body-cell-text">
            <div class="cooperations-property-body-cell-text-title">优先独栋，集中式物业</div>
            <div class="cooperations-property-body-cell-text-content">优先整栋物业合作，至少60间以上，合作时限至少10年以上</div>
          </div>
        </div>
        <div class="cooperations-property-body-cell">
          <div class="cooperations-property-body-cell-img">
            <img src="../../assets/PC/cooperation/property_icon_7.png" alt />
          </div>
          <div class="cooperations-property-body-cell-text">
            <div class="cooperations-property-body-cell-text-title">优先考虑商圈位置</div>
            <div class="cooperations-property-body-cell-text-content">城市中心，商圈周边，靠近交通主要站点，地铁口、车站等繁华地段</div>
          </div>
        </div>
      </div>
    </div>

    <div class="copperations-join">
      <el-cell message="加盟方式"></el-cell>

      <div class="copperations-join-contact">
        <div class="copperations-join-contact-cell">
          <div class="copperations-join-contact-cell-title">加盟</div>
          <div>18002675029</div>
        </div>
        <div class="copperations-join-contact-cell">
          <div class="copperations-join-contact-cell-title">合作</div>
          <div>18002675029</div>
        </div>
        <div class="copperations-join-contact-cell">
          <div class="copperations-join-contact-cell-title">直营</div>
          <div>18002675029</div>
        </div>
      </div>
    </div>
    <el-footer></el-footer>
  </div>
</template>

<script>
import elHead from "../../components/PC/head";
import elCell from "../../components/PC/cell";
import elFooter from "../../components/PC/footer";
var mapData = require("../../assets/DG.json");
var flagData = require("../../assets/flag.json");
var housingData = require("../../assets/housing.json");
export default {
  name: "cooperation",
  components: {
    elHead,
    elCell,
    elFooter
  },
  mounted() {
    this.drawLine();
  },
  methods: {
    drawLine() {
      let myChart = this.$echarts.init(
        document.getElementById("mapChart"),
        "dark"
      );
      this.$echarts.registerMap("dongguan", mapData);
      let retData = [];
      housingData.forEach((item) => {
        retData.push({
          name: item["rh_name"],
          value: [item["rh_longitude"], item["rh_latitude"], item["roomNumber"]]
        });
      });
      let option = this.initChartOption(retData, flagData);
      myChart.setOption(option, true);
    },

    initChartOption(retData, flagMap) {
      let option = {
        backgroundColor: "#f2f3f5",
        visualMap: {
          show: true,
          min: 0,
          max: 1000,
          left: 40,
          seriesIndex: 2, //指定对应哪个series对象
          type: "piecewise",
          bottom: 50,
          textStyle: {
            color: "black"
          },
          splitList: [
            {
              gt: 90,
              color: "#00eaff",
              label: "90以上"
            },
            {
              gte: 60,
              lte: 90,
              color: "#ffde00 ",
              label: "60-90"
            },
            {
              gte: 30,
              lte: 60,
              color: "#fc9700",
              label: "30-60"
            },
            {
              lte: 30,
              color: "#f44336",
              label: "30以下"
            }
          ]
        },
        geo: {
          show: true,
          map: "dongguan",
          zoom: 1.2,
          label: {
            //map 已用了geo 显示文字这里设置
            normal: {
              color: "#00ffff",
              show: true
            },
            emphasis: {
              color: "#00ffff",
              show: false
            }
          },
          roam: true,
          itemStyle: {
            normal: {
              areaColor: "transparent",
              borderColor: "#3fdaff",
              borderWidth: 2,
              shadowColor: "rgba(63, 218, 255, 0.5)",
              shadowBlur: 30
            },
            emphasis: {
              areaColor: "transparent"
            }
          }
        },
        series: [
          {
            name: "插旗坐标",
            type: "scatter",
            symbol: "pin",
            coordinateSystem: "geo",
            data: flagMap,
            symbolSize: 30,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true,
                formatter: "{b}",
                position: "right",
                color: "#ffffff"
              }
            },
            itemStyle: {
              normal: {
                color: "#fe0000" //坐标点颜色
              }
            }
          },
          {
            type: "map",
            map: "dongguan",
            geoIndex: 0,
            aspectScale: 0.75, //长宽比
            showLegendSymbol: false, // 存在legend时显示
            label: {
              show: false
            },
            roam: true,
            zoom: 1.2,
            animation: true,
            data: []
          },
          {
            name: "",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: retData,
            symbolSize: 10,
            // symbolSize: function (val) {
            //     return val[2] / 5;
            // },
            showEffectOn: "render",
            rippleEffect: {
              period: 15,
              scale: 4,
              brushType: "fill"
            },
            hoverAnimation: true,
            label: {
              normal: {
                show: false
              },
              emphasis: {
                show: true,
                textStyle: {
                  color: "#fff"
                },
                formatter: function(params) {
                  var value = params.value[2];
                  return params.name + " \n" + value + "间";
                },
                position: "right"
              }
            },
            itemStyle: {
              normal: {
                color: "#ffff",
                shadowBlur: 10,
                shadowColor: "#333"
              }
            },
            zoom: 1.2,
            zlevel: 1
          }
        ]
      };
      return option;
    }
  }
};
</script>

<style>
.cooperation {
  padding-top: 72px;
  width: 100%;
  height: 100%;
}

.cooperation-banner {
  width: 100%;
}

.cooperation-banner img {
  width: 100%;
  height: 500px;
}

.cooperations-layout-body {
  margin: 92px auto;
  display: flex;
  justify-content: space-between;
  width: 1200px;
}

.cooperations-layout-body-content {
  width: 276px;
  height: 344px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
}

.cooperations-layout-body-content:hover {
  animation: myfirst 0.5s;
}

@keyframes myfirst {
  from {
    position: relative;
    top: 0;
  }
  to {
    position: relative;
    top: -20px;
  }
}

.cooperations-layout-body-content img {
  margin: 32px;
  width: 132px;
  height: 132px;
}

.cooperations-layout-body-content-title {
  margin-top: 16px;
  font-size: 20px;
  font-weight: 600;
}

.cooperations-layout-body-content-text {
  width: 192px;
  margin: 35px auto;
  font-weight: 400;
}

.cooperations-market {
  background: #f2f3f5;
}

.cooperations-Statistics {
  display: flex;
  width: 1000px;
  margin: 60px auto;
  justify-content: space-between;
}

.cooperations-Statistics-cell {
  display: flex;
  font-size: 20px;
  color: #222222;
}

.cooperations-Statistics-cell img {
  margin-right: 12px;
  width: 32px;
  height: 32px;
}

.cooperations-Statistics-cell span {
  color: #07bac6;
  font-size: 24px;
}

.cooperations-Statistics-img {
  text-align: center;
}

.cooperations-Statistics-img img {
  width: 1079px;
  height: 720px;
}

.cooperations-property-body {
  margin: 74px auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.cooperations-property-body-cell {
  display: flex;
}

.cooperations-property-body-cell-img img {
  width: 54px;
  height: 54px;
}

.cooperations-property-body-cell-text {
  margin-left: 20px;
}

.cooperations-property-body-cell-text-title {
  font-size: 20px;
  color: #222222;
  margin-bottom: 10px;
}

.cooperations-property-body-cell-text-content {
  font-size: 14px;
  width: 285px;
}

.copperations-join {
  padding-bottom: 80px;
}

.copperations-join-contact {
  margin: 80px auto;
  width: 1200px;
  display: flex;
  justify-content: space-between;
}

.copperations-join-contact-cell {
  width: 320px;
  height: 68px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 20px 20px;
  font-size: 32px;
  color: #222222;
  font-weight: 400;
}

.copperations-join-contact-cell-title {
  margin-bottom: 12px;
  font-size: 24px;
  color: #565656;
}

.dg-map {
  width: 1079px;
  height: 720px;
  margin: 32px auto 0 auto;
}
</style>
  
