import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import AmapVue from '@amap/amap-vue'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts



AmapVue.config.key = '063fe8246b924ec8bcdbcd4e31090196'

Vue.use(AmapVue)
Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
